import EncryptUtility from "../../utility/js/encrypt-utility.js";
import authHeader from "../../../services/auth-header";
import jwt_decode from "jwt-decode";
import { APPCODE } from "../../utility/js/constants-utility";
// import BrowserUtility from "../../utility/js/browser-utility";
import Utility from "../../../shared/utility";
export default {
  data() {
    return {
      logoImg: require("@/assets/images/RLP.png"),
      valid: true,
      email: "",
      password: "",
      isPasswordShow: false,
      userId: 0,
      decoded: [],
      expiredTime: 0,
      currentTime: 0,
      urlArray: [],
      uralArrayPageAction: [],
      permission: [],
      emailRules: [(v) => !!v || "Email is required", (v) => /.+@.+\..+/.test(v) || "Email must be valid"],
      passwordRules: [(v) => !!v || "Password is required"],
      ipData: [],
    };
  },
  mounted() {
    EncryptUtility.localStorageEncrypt("showNav", false.toString());
    this.$store.commit("ConfigModule/showNav", false);
    this.$vuetify.theme.dark = false;
    localStorage.clear();
    this.getIP();
  },
  methods: {
    //To show password
    showPassword() {
      this.isPasswordShow = !this.isPasswordShow;
    },
    //User Login
    login() {
      localStorage.clear();
      if (!this.$refs.loginForm.validate()) return false;
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      var cipherpassword = EncryptUtility.passwordEncrypt(this.password);
      // var decrypted = EncryptUtility.passwordDecrypt(cipherpassword);
      let loginModel = {
        Email: this.email,
        Password: cipherpassword,
        Appcode: APPCODE,
        // SystemInfo: JSON.stringify(BrowserUtility.browserDetails()),
        SystemInfo: JSON.stringify(this.ipData),
      };
      this.axios
        .post("ap/login", loginModel)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let userIP = this.ipData?.ip;
            localStorage.clear();
            EncryptUtility.localStorageEncrypt("printerIP", userIP);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            EncryptUtility.localStorageEncrypt("showNav", true.toString());
            this.$store.commit("ConfigModule/showNav", true);
            let responseData = response.data.body;
            EncryptUtility.localStorageEncrypt("userID", responseData.UserId.toString());
            this.userId = responseData.UserId;
            EncryptUtility.localStorageEncrypt("fname", responseData.FName.toString());
            EncryptUtility.localStorageEncrypt("lname", responseData.LName.toString());
            EncryptUtility.localStorageEncrypt("email", responseData.Email.toString());
            EncryptUtility.localStorageEncrypt("installId", responseData.InstallId.toString());
            EncryptUtility.localStorageEncrypt("Token", responseData.Jwt);
            let printerData = JSON.parse(responseData.Printer);
            if (responseData?.Printer?.length !== 0 && responseData?.Printer !== null && responseData?.Printer !== undefined) {
              if (printerData[0]?.p_name !== null && printerData[0]?.p_name !== "") {
                EncryptUtility.localStorageEncrypt("primaryCheck", "true");
                EncryptUtility.localStorageEncrypt("primaryName", printerData[0]?.p_name);
                EncryptUtility.localStorageEncrypt("primaryLabelSize", printerData[0]?.p_size);
                EncryptUtility.localStorageEncrypt("primaryPrinterType", printerData[0]?.p_type);
              }
              if (printerData[0]?.s_name !== null && printerData[0]?.s_name !== "") {
                EncryptUtility.localStorageEncrypt("secondaryCheck", "true");
                EncryptUtility.localStorageEncrypt("secondaryName", printerData[0]?.s_name);
                EncryptUtility.localStorageEncrypt("secondaryLabelSize", printerData[0]?.s_size);
                EncryptUtility.localStorageEncrypt("secondaryPrinterType", printerData[0]?.s_type);
              }
              if (printerData[0]?.default_printer !== null && printerData[0]?.default_printer !== "") {
                EncryptUtility.localStorageEncrypt("defaultCheck", "true");
                EncryptUtility.localStorageEncrypt("defaultName", printerData[0]?.default_printer);
              }
              EncryptUtility.localStorageEncrypt("printerID", printerData[0]?.printer_id?.toString());
            }
            authHeader(responseData.Jwt);
            this.decoded = jwt_decode(responseData.Jwt);
            this.expiredTime = this.decoded.exp * 1000;
            EncryptUtility.localStorageEncrypt("JWTExp", JSON.stringify(this.decoded.exp));
            EncryptUtility.localStorageEncrypt("themeCode", JSON.stringify(responseData.ThemeCode));
            if (responseData.ThemeCode == "Dark") {
              this.$vuetify.theme.dark = true;
              EncryptUtility.localStorageEncrypt("theme", JSON.stringify(this.$vuetify.theme.dark));
            } else if (responseData.ThemeCode == "Light") {
              this.$vuetify.theme.dark = false;
              EncryptUtility.localStorageEncrypt("theme", JSON.stringify(this.$vuetify.theme.dark));
            } else {
              this.$vuetify.theme.dark = false;
              EncryptUtility.localStorageEncrypt("theme", JSON.stringify(this.$vuetify.theme.dark));
            }
            this.$router.push("/home");
            this.getMenuList();
            this.getExipryTime();
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.password = "";
          this.errorMessage = error.message;
        });
    },
    //To store URL in local storage
    getMenuList() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`ap/user_menu?user_id=${this.userId}`)
        .then((response) => {
          if (
            response.status == 200 &&
            response.data !== undefined &&
            response.data.body !== undefined &&
            response.data.body.message !== undefined &&
            response.data.body.message !== undefined &&
            response.data.body.message !== null
          ) {
            let menuResponseData = response.data.body.message;
            if (menuResponseData !== "" && menuResponseData !== undefined && menuResponseData !== null) {
              this.permission = JSON.parse(menuResponseData).Menus;
              //To Travarse Group level
              this.permission?.forEach((element) => {
                //To Travarse sub group
                element?.Childs.forEach((childelement) => {
                  //To Traverse between groups
                  childelement.ChildOfChilds?.forEach((childofchildelement) => {
                    //TO Traverse between Pages and get the URl
                    this.urlArray.push(childofchildelement.Url);
                    // To get the URLs in Page Level
                    //To Check if Child of child is present or not
                    if (childofchildelement?.ChildOfChildOfChilds != undefined) {
                      childofchildelement?.ChildOfChildOfChilds.forEach((childofchildofchildelement) => {
                        //Push the Values inside a new Array
                        this.urlArray.push(childofchildofchildelement.Url);
                      });
                    }
                  });
                });
              });
              // To remove empty values
              this.urlArray = this.urlArray.filter((item) => item);
              //To Show the user Profile Page;
              this.urlArray.push("user-profile");
              this.urlArray.push("printer-settings");
              this.urlArray.push("report-issue");
              this.urlArray.push("report-issue-admin");
              this.urlArray.push("app-role-copy");
              this.urlArray.push("app-role-add-copy");
              this.urlArray.push("app-role-edit-copy");
              this.urlArray.push("test");
              // To store the values in localstorage
              EncryptUtility.localStorageEncrypt("Url", JSON.stringify(this.urlArray));
            } else {
              let Alert = {
                type: "error",
                isShow: true,
                message: "User is not associated with any role or do not have permission to menu.",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //To check the expiration time and clear the local storage
    getExipryTime() {
      this.currentTime = new Date().getTime();
      if (this.currentTime > this.expiredTime) localStorage.clear();
    },
    //Get IP details
    getIP() {
      this.axios.get("https://ipapi.co/json/").then((response) => {
        this.ipData = [];
        this.ipData = response.data;
      });
    },
  },
};
